<template>
  <div class="map_wrap">
    <div id="map">
    </div>

    <div id="menu_wrap" class="bg_white">
        <div class="option">
            <div>
              <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;" 
                id="farm" 
                ref="farm" 
                label="농장/사업장✽" 
                v-model="farm"
                :items="farmItems"
                :menu-props="{ top: false, offsetY: true }"
                no-data-text="자료(권한)이 없습니다."
                item-text="farm_name"
                return-object
                @focus="comboFarms()"
                @change="refresh()"
              />
            </div>
        </div>

        <hr>
        
        <div id="pagination" v-for="(room, a) in room_list" :key="a">
          <div class="info__content" @click="overlayOpen(room)">
              <div>{{ room.room_name }}</div>
          </div>
        </div> 
      </div>
    </div>
</template>

<script>
import dateUtil from "@/utils/custom/dateProperty.js";
import Apis from '@/api/apis'

var setTime = null;

export default {
  name: "SensorMap2",
  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    } ,() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    this.comboFarms();

    setTime = setInterval(() => this.refreshValue(), 20 * 1000); // 20초 간격 갱신
  },
  mounted() {
    // 카카오 객체가 있고, 맵 그리는 준비가 되어있다면 맵 실행
    if (window.kakao && window.kakao.maps) {
      this.initMap();
    } else {
      const script = document.createElement("script");
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src =
      "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=4799d9355ed066eb69a917b633b871e4";   //hyerin key
      document.head.appendChild(script);
    }
  },
  data() {
    return {
      infowindows: [],
      room_list:[],

      map: null,
      markers: [],

      farm:{},
      farmItems:[],

      overlays: {}, 
      setLevel:false,
      levelAboveThreeFound:false,

    };
  },
  destroyed() {
    clearInterval(setTime);
  },
  methods: {
    overlayOpen(room){
      // room.isActive = !room.isActive;

      if (this.overlays[room.room_cd] && this.overlays[room.room_cd].getMap()) {
        this.overlays[room.room_cd].setMap(null);
      } else {
        this.showCustomOverlay(room);
      }
    },
    refresh(){
      Apis.listSensorsInFarmForMap({
        farm_cd : this.farm.farm_cd
        },(res) => {  
        this.$store.commit("resMessage",res.message);
        this.room_list = res.data;

        this.loadMarkers();
        // console.log(res.data)
        }).catch( (err) => {  // API 오류 처리
        console.log("comboFarms API 호출 오류",err)
        alert(err);
      }) 
    },
    async initMap() {
      const mapContainer = document.getElementById('map');
      const  mapOption = {
          center: new kakao.maps.LatLng(36.527887, 126.561656), 
          level: 5,
          mapTypeId: kakao.maps.MapTypeId.HYBRID,
        };

      this.map = new kakao.maps.Map(mapContainer, mapOption);
      // this.refresh();
    },
    loadMarkers(){
      this.markers.forEach(marker => marker.setMap(null));
      this.markers = [];
      // this.overlays = {};
      
        // 기존 오버레이들을 모두 제거
      for (let key in this.overlays) {
        this.overlays[key].setMap(null);
      }
      this.overlays = {};

      const imageSrc = require('@/assets/map_marker.svg');
      const imageSize = new kakao.maps.Size(50, 50);
      const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);

      this.room_list.forEach(roomInfo => {
        const marker = new kakao.maps.Marker({
          map: this.map,
          position: new kakao.maps.LatLng(roomInfo.lat, roomInfo.lng),
          image: markerImage,
        });

        // 배열을 마커에 저장
        this.markers.push(marker);

        // 마커에 이벤트 리스너
        kakao.maps.event.addListener(marker, 'click', () => {
          // this.markers.push(marker);
          // this.showCustomOverlay(roomInfo)

          // 오버레이가 이미 존재하는지 확인하고 토글
          if (this.overlays[roomInfo.room_cd] && this.overlays[roomInfo.room_cd].getMap()) {
            this.overlays[roomInfo.room_cd].setMap(null);
          } else {
            this.map.panTo(new kakao.maps.LatLng(roomInfo.lat, roomInfo.lng));
            this.showCustomOverlay(roomInfo);
          }
        
        })

           // 모든 마커의 경위도를 포함하는 bounds 객체 생성
      const bounds = new kakao.maps.LatLngBounds();

      this.markers.forEach(marker => {
        marker.setMap(this.map);
        bounds.extend(marker.getPosition());
      });
      this.map.setBounds(bounds);
      

    })


    },

    showCustomOverlay(roomInfo){
      // console.log(roomInfo)
      
      Apis.roomMonitoringSummary({
        room_cd: roomInfo.room_cd,
        stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss")
      }, (res) => {
        let data = {
          temp: res.data.temp,
          hum: res.data.hum,
          co2: res.data.co2,
          nh3: res.data.nh3,
          h2s: res.data.h2s
        };

        var content = document.createElement('div');
        content.className = 'overlaybox';
        content.onclick = function(event) {
          event.stopPropagation(); // 상위 지도 클릭 이벤트 방지
          customOverlay.setZIndex(9999); // 클릭 시 z-index 조정
        };


        //돈방이름
        var title = document.createElement('div');
        title.setAttribute('id', roomInfo.room_cd);
        title.className = 'map-popup-title';

        var room_name = document.createElement('span');
        room_name.className = 'popup-name';
        room_name.appendChild(document.createTextNode(roomInfo.room_name));
        title.appendChild(room_name);
        content.appendChild(title);

        //닫기버튼
        var close = document.createElement('h3');
        close.className = 'close';
        title.appendChild(close);
        content.appendChild(title);
        
        close.onclick = function(event) {
          event.stopPropagation(); // 상위박스 클릭이벤트 방지
          customOverlay.setMap(null);
        };

        var value_content = document.createElement('div');
        value_content.className = 'value_overlaybox';
        content.appendChild(value_content);

        // 데이터 삽입
        const createValueElement = (iconClass, name, value, unit) => {
          var valueDiv = document.createElement('div');
          valueDiv.className = 'map-popup-value';

          var icon = document.createElement('span');
          icon.className = iconClass;
          valueDiv.appendChild(icon);
          value_content.appendChild(valueDiv);

          var valueSpan = document.createElement('span');
          valueSpan.setAttribute('id', roomInfo.room_cd + name);
          valueSpan.className = 'popup-value';
          valueSpan.appendChild(document.createTextNode(value));
          valueDiv.appendChild(valueSpan);
          value_content.appendChild(valueDiv);

          var unitSpan = document.createElement('span');
          unitSpan.className = 'popup-value-unit';
          unitSpan.appendChild(document.createTextNode(unit));
          valueDiv.appendChild(unitSpan);
          value_content.appendChild(valueDiv);
        };

        if (data.temp !== '-') createValueElement('temp_icon', '_temp' ,data.temp.current || '', '℃');
        if (data.hum !== '-') createValueElement('hum_icon', '_hum' ,data.hum.current || '', '%');
        if (data.co2 !== '-') createValueElement('co2_icon', '_co2' ,data.co2.current || '', 'ppm');
        if (data.nh3 !== '-') createValueElement('nh3_icon', '_nh3' ,data.nh3.current || '', 'ppm');
        if (data.h2s !== '-') createValueElement('h2s_icon', '_h2s' ,data.h2s.current || '', 'ppm');

        this.overlays[roomInfo.room_cd] = customOverlay;

        var customOverlay = new kakao.maps.CustomOverlay({
          position: new kakao.maps.LatLng(roomInfo.lat, roomInfo.lng),
          xAnchor: 0.5, // 커스텀 오버레이의 x축 위치입니다. 1에 가까울수록 왼쪽에 위치합니다. 기본값은 0.5 입니다
          yAnchor: 1.3,
        });

        customOverlay.setContent(content);
        customOverlay.setMap(this.map);

        this.overlays[roomInfo.room_cd] = customOverlay;

      }, (error) => {
        // 오류 처리 코드
        console.error("monitoringSummary 호출 오류", error);
      });

      // this.markers.push(this.marker);


    },

    // 해당 센서노드 팝업 시 새로고침
    refreshValue(){
      // this.$store.commit("resMessage","");    

      this.room_list.forEach(async (room) =>
        {
          // console.log(this.$store.getters.resMessage)
          // console.log(room)
          if (document.getElementById(room.room_cd)) {     
            await Apis.roomMonitoringSummary({
              room_cd: room.room_cd,
              stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
              } ,(res) => {  // 정상처리
                // console.log(res) 
  
                var old = document.getElementById(room.room_cd + '_temp');

                if (old) {
                  var temp =  document.createElement('span');
                  temp.setAttribute( 'id', room.room_cd + '_temp');
                  temp.className = 'popup-value';
                  temp.appendChild(document.createTextNode(res.data.temp.current || ''));

                  old.replaceChild(temp,old.firstChild);

                  if (this.$store.getters.resMessage < "측정 : " + res.data.temp.chktime)  this.$store.commit("resMessage","측정 : " + res.data.temp.chktime);       
                
                } 

                old = document.getElementById(room.room_cd + '_hum');
                if (old) {
                  var hum =  document.createElement('span');
                      hum.setAttribute( 'id', room.room_cd + '_hum');
                      hum.className = 'popup-value';
                      hum.appendChild(document.createTextNode(res.data.hum.current || ''));

                      old.replaceChild(hum,old.firstChild);  
                } 

                old = document.getElementById(room.room_cd + '_co2');
                if (old) {
                  var co2 =  document.createElement('span');
                      co2.setAttribute( 'id', room.room_cd + '_co2');
                      co2.className = 'popup-value';
                      co2.appendChild(document.createTextNode(res.data.co2.current || ''));

                      old.replaceChild(co2,old.firstChild);
                } 

                old = document.getElementById(room.room_cd + '_nh3');
                if (old) {
                  var nh3 =  document.createElement('span');
                      nh3.setAttribute( 'id', room.room_cd + '_nh3');
                      nh3.className = 'popup-value';
                      nh3.appendChild(document.createTextNode(res.data.nh3.current || ''));

                      old.replaceChild(nh3,old.firstChild);
                      // console.log("new",res.data.nh3.current || '');    
                } 

                old = document.getElementById(room.room_cd + '_h2s');
                if (old) {
                  var h2s =  document.createElement('span');
                      h2s.setAttribute( 'id', room.room_cd + '_h2s');
                      h2s.className = 'popup-value';
                      h2s.appendChild(document.createTextNode(res.data.h2s.current || ''));

                      old.replaceChild(h2s,old.firstChild);
                      // console.log("new",res.data.h2s.current || '');    
                } 

            }, (error) => {
              // 오류 처리 코드
              console.error("monitoringSummary 호출 오류", error);
            });
          }
        }
      );
    },
    comboFarms() {
      Apis.comboFarms({
        company_cd: "",
        new_version : false,
        for_map : true,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
        if (res.result) {
            this.farmItems = res.data;
          } else {
            this.farmItems = [];
            alert(res.message);  
          } 
          }).catch( (err) => {  // API 오류 처리
          console.log("comboFarms API 호출 오류",err)
          alert(err);
        }
      ) 
    },

  },
};
</script>

<style>
#map {
  width:100%;
  height:100vh;
  position:relative;
  overflow:hidden;
}
.map_wrap, .map_wrap * {
  margin:0;
  padding:0;
  font-size:15px;
}
.map_wrap a, .map_wrap a:hover, .map_wrap a:active{
  color:#000;
  text-decoration: none;
}
.map_wrap {
  width:100%;
  height:auto;
  position: relative; 
}
#menu_wrap {
  position:absolute;
  top:0;
  left:0;
  /* bottom:0; */
  width:250px;
  margin:10px 0 30px 10px;
  padding:5px;
  /* overflow-y:auto; */
  background:rgba(255, 255, 255, 0.7);
  z-index: 1;
  font-size:12px;
  border-radius: 10px;
}
.bg_white {
  background:#fff;
}
.info__content{
  font-weight:600;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  margin:5px;
  padding:10px;
  border-radius: 15px;
  cursor:pointer;
}
.info__content.active {
  /* background-image: linear-gradient(120deg, #fffb7b 0%, #fffb00 100%);  */
  background-color:red;
}
#menu_wrap hr {
  display: block; 
  height: 1px;
  border: 0; 
  border-top: 2px solid #5F5F5F;
  margin:3px 0;
}

.button-group {
  margin: 10px 0px;
}
button {
  margin: 0 3px;
}
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 17px;
  height: 17px;
  background: url('../../assets/value/overlay_close.png');
}
.close:hover {
  cursor: pointer;
}
.overlaybox{
  width:100%;
  /* min-width:200px; */
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  border-radius: 20px;
  transition:all 500ms linear;
  box-shadow: 0 5px 10px 0 rgb(255, 255, 255, 0.4);
}

.overlaybox:active{
  transition:all 500ms linear;
}
.overlaybox:after{
  content:'';
  position: absolute;
  margin-left: -11px;
  left: 50%; 
  bottom: -12px;
  width: 22px;
  height: 12px;
  background:url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png) no-repeat 0 bottom;
}
.value_overlaybox{
  padding:5px 5px 15px 5px;
}
.map-popup-title{
  border-radius: 20px 20px 0 0;
  background-image: linear-gradient(60deg, #96deda 0%, #50c9c3 100%);
  color:white;
  overflow: hidden;
  padding:10px 30px 10px 15px;
  width:100%;
  margin-right:20px;
}
.popup-name{
  font-size:1.3rem;
  font-weight:600;
  width:100%;
}

.map-popup-value{
  padding:5px 10px 0 10px;
  display:flex;
  justify-content: space-evenly; 
}
.popup-value-title{
  letter-spacing: -0.74px;
  font-weight:600;
}
.popup-value{
  width:80px;
  text-align: right;
  font-size:1.2rem;
}
.popup-value-unit{
  margin-left:5px;
  margin-top:4px;
  font-size:0.8rem;
}
.popup-buttons{
  text-align: right;
}
.popup-button{
  background-color:#3a4f4f;
  padding:8px;
  color:white;
  border-radius: 10px;
  font-weight:600;
}
/* 아이콘 */
.temp_icon{
  margin-top:2px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../../assets/monitoring/temp.svg') no-repeat;
}
.hum_icon{
  margin-top:2px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../../assets/monitoring/humidity.svg') no-repeat;
}
.co2_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../../assets/monitoring/co2.svg') no-repeat;
}
.nh3_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../../assets/monitoring/nh3.svg') no-repeat;
}
.h2s_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../../assets/monitoring/h2s.svg') no-repeat;
}

</style>
